<template>
  <div>
    <h3 ref="intro">
      Fraction
    </h3>
    <p>
      A fraction is used to represent a part or portion of a whole object. A fraction is sometimes also called a fractional number.
      Take this example: imagine you bought a 6-slice pizza. Then 1/2 of that pizza would be 3 out of the 6 slices of the pizza.
    </p>
    <h3 ref="types">
      Types of Fractions
    </h3>
    <ul class="a">
      <li> <h5> Proper Fraction </h5> </li>
      <p>
        A proper fraction represents a portion or a part of a whole object. In a proper fraction, the denominator is greater than the numerator.
        An example of proper fraction would be 3/4 &mdash; which represents 3 slices of a 4-slice object.
      </p>
      <li> <h5> Improper Fraction </h5> </li>
      <p>
        An improper fraction is a sum of a whole number and a proper fraction. In an improper fraction, the numerator is greater than the denominator.
        An example of improper fraction would be \(\frac{5}{4}\) &mdash; which represents 1 whole pizza plus 1 slice of a 4-slice pizza.
      </p>
      <li> <h5> Mixed Fraction </h5> </li>
      <p>
        When an improper fraction is represented as a combination of a whole number and a proper fraction then it is called a mixed fraction.
        An example of mixed fraction would be \(1\frac{1}{4}\) &mdash; which is same as \(\frac{5}{4}\).
      </p>
    </ul>
    <h3 ref="pg">
      MagicGraph | Understanding Fractional Numbers
    </h3>
    <p> In this MagicGraph, you will learn about fractions by example of slices of a pizza.</p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Fractions',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Fractions';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Fractions', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Types of Fractions', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Fractions',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about Thales Theorem'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
